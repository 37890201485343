<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <vxe-modal ref="xModal" title="选择报修师傅" width="800" height="500" show-zoom>
    <template v-slot>
      <SelectRepairMaster :selectRow="selectRow" />
    </template>
  </vxe-modal>

  <vxe-modal ref="xModalLog" title="订单日志" width="800" height="500">
    <template v-slot>
      <div class="log">
        <el-steps direction="vertical" :active="logList.length">
          <el-step :title="item.context + '【' + item.workerName + '】'" :description="item.createTime" v-for="(item,index) in logList" :key="index"></el-step>
        </el-steps>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import SelectRepairMaster from './SelectRepairMaster'
export default {
  components: {
    SelectRepairMaster
  },
  data() {
    return {
      currNode: {},
      selectRow: null,
      repairTypes: [],
      logList: [],
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [
            { code: "create", name: "电话报修", status: "primary" },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "repairObjName",
            title: "房间号",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间号",
              },
            },
          },
          {
            field: "repairName",
            title: "报修人",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入报修人",
              },
            },
          },
          {
            field: "tel",
            title: "联系方式",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "context",
            title: "报修内容",
            minWidth: 220,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入报修内容",
              },
            },
          },
          {
            field: "repairWay",
            title: "派单方式",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "sendWay",
              props: {
                maxlength: 24,
                placeholder: "请选择派单方式",
              },
            },
          },
          {
            field: "source",
            title: "来源",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "source",
              props: {
                maxlength: 24,
                placeholder: "请选择来源",
              },
            },
          },
          {
            field: "workerName",
            title: "处理人",
            minWidth: 120,
            editRender: {
              name: "$select",
              props: {
                maxlength: 24,
                placeholder: "请选择来源",
              },
            },
          },
          {
            field: "workerTel",
            title: "处理人电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入处理人电话",
              },
            },
          },
          {
            field: "returnVisitFlag",
            title: "是否回访",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否回访",
              },
            },
          },
          {
            field: "status",
            title: "状态",
            width: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "repairStatus",
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 220,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "sendSingle",
                  status: "primary",
                  label: "派单",
                  params: {
                    property: "status",
                    value: ["1"]
                  },
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                  params: {
                    property: "status",
                    value: ["1"]
                  },
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
                {
                  name: "detail",
                  status: "primary",
                  label: "详情",
                  events: {
                    click: (row) => {
                      this.logEvent(row)
                    }
                  }
                },
              ],
            },
          },
        ],
        editConfig: {
          enabled: false,
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              props: {
                placeholder: "请选择所属上级",
              },
              expandAll: true,
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["communityId"] = node.communityId;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                },
              },
            },
          },
          {
            field: "repairSettingId",
            title: "报修项目",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                maxlength: 24,
                placeholder: "请选择报修项目",
              },
              events: {
                change: ({ data, property }) => {
                  let item = this.repairTypes.filter(item => item.id === data[property]);
                  data["repairWay"] = item && item.length ? item[0].repairWay : "";
                }
              }
            },
          },
          {
            field: "repairObjName",
            title: "房间号",
            span: 12,
            itemRender: {
              name: "$VxeSelectRoom",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                maxlength: 24,
                placeholder: "请选择房间号",
              },
              events: {
                click: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["repairObjId"] = row.id;
                  data["repairName"] = row.ownerName;
                  data["tel"] = row.ownerLink;
                }
              }
            },
          },
          {
            field: "repairName",
            title: "报修人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择报修人",
              },
            },
          },
          {
            field: "tel",
            title: "联系方式",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "appointmentTime",
            title: "预约时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择预约时间",
              },
            },
          },
          {
            field: "returnVisitFlag",
            title: "是否回访",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否回访",
              },
            },
          },
          {
            field: "context",
            title: "报修内容",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入报修内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          repairSettingId: [{ required: true, message: "请选择报修类型" }],
          repairObjName: [{ required: true, message: "请选择房间号" }],
          repairName: [{ required: true, message: "请选择报修人" }],
          tel: [{ required: true, message: "请输入联系方式" }],
          appointmentTime: [{ required: true, message: "请选择预约时间" }],
          returnVisitFlag: [{ required: true, message: "请选择是否回访" }],
          enabled: [{ required: true, message: "请选择状态" }],
          context: [{ required: true, message: "请输入报修内容" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          repairSettingId: "",
          repairObjName: "",
          repairName: "",
          tel: "",
          returnVisitFlag: "0",
          appointmentTime: "",
          enabled: "1",
          source: "20"
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "repairObjName",
            title: "房间号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间号",
              },
            },
          },
          {
            field: "repairWay",
            title: "派单方式",
            itemRender: {
              name: "$select",
              optionTypeCode: "sendWay",
              props: {
                placeholder: "请选择派单方式",
              },
            },
          },
          {
            field: "source",
            title: "来源",
            itemRender: {
              name: "$select",
              optionTypeCode: "source",
              props: {
                placeholder: "请选择来源",
              },
            },
          },
          {
            field: "status",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "repairStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          repairObjName: "",
          repairWay: "",
          source: "",
          status: ""
        },
      },
    };
  },

  methods: {
    ...mapActions(['findByEventService', 'submitEventService']),
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.getRepairsetting(node)
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");

      this.formOptions.data["organizationId"] = node.parentId;
      this.formOptions.data["parentId"] = node.id;
      this.formOptions.data["communityName"] = node.label;
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    // 获取报修设置分类
    getRepairsetting(node) {
      this.findByEventService({
          service: "/wisdom/repairSetting",
          params: {
            parentId: node.id,
            enabled: "1"
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.repairTypes = data;
            // this.gridOptions.columns = [...this.$Tools.buildDataToGrid(this.gridOptions.columns, data, "repairSettingId", { value: "id", label: "repairTypeName" })];
            this.formOptions.items = [...this.$Tools.buildDataToGrid(this.formOptions.items, data, "repairSettingId", { value: "id", label: "repairTypeName" })];
          }
        });
    },

    sendSingleEvent(row) {
      if (this.loading) return;
      this.loading = true;
      this.selectRow["workerId"] = row.workerId;
      this.selectRow["workerName"] = row.workerName;
      this.selectRow["workerTel"] = row.phone;
      this.submitEventService({
        service: "/wisdom/repairPool/updateStatus",
        params: this.selectRow,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
          this.$message.success("派单成功");
          this.$refs.xModal.close();
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 查看日志
    logEvent(row) {
      this.findByEventService({
        service: '/wisdom/repairUser',
        params: {
          repairId: row.id
        },
      }).then(({ code, data, message }) => {
        if (code == 200) {
          data.forEach(item => {
            if (item.status == 1) {
              item.context = "创建报修"
            }
            if (item.status == 2) {
              item.context = "已指派处理人"
            }
            if (item.status == 3) {
              item.context = "已转单"
            }
            if (item.status == 4) {
              item.context = "已结单"
            }
          });
          this.logList = data;
          this.$refs.xModalLog.open();
        }
      })
    },

    resetEvent() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
